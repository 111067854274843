import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#5a7db9',
        },
        secondary: {
            main: '#140a6e',
        },
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});
