import * as React from 'react';
import Box from '@mui/material/Box';
import {Divider, Drawer, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import {useDispatch, useSelector} from "react-redux";
import {close} from "../../store/leftMenuSlice";
import {Trans} from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import {NAVIGATION_PAGES} from "../../config/AppConstants";

function LeftMenu() {
    const isLeftMenuOpened = useSelector((state) => state.leftMenu.isOpened);
    const dispatch = useDispatch();

    const DrawerList = (
        <Box sx={{width: 250}} role="presentation" onClick={() => dispatch(close())}>
            <List>
                <ListItem key="settings" disablePadding>
                    <ListItemButton component={Link} to='/'>
                        <ListItemIcon>
                            <HomeIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            <Trans i18nKey="menu.home"/>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider/>
            <List>
                {
                    NAVIGATION_PAGES.map((page) => (
                        <ListItem key={`left-menu-${page.name}`} disablePadding>
                            <ListItemButton component={Link} to='#'>
                                <ListItemIcon>
                                    <LabelImportantIcon/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Trans i18nKey={page.name}/>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </List>
            <Divider/>
            <List>
                <ListItem key="left-menu-search" disablePadding>
                    <ListItemButton component={Link} to='/'>
                        <ListItemIcon>
                            <SearchIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            <Trans i18nKey="menu.search"/>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );
    return (
        <Drawer variant="temporary"
                open={isLeftMenuOpened}
                onClose={() => dispatch(close())}>
            {DrawerList}
        </Drawer>
    );
}

export default LeftMenu;