import {createSlice} from '@reduxjs/toolkit'

export const leftMenuSlice = createSlice({
    name: 'leftMenu',
    initialState: {
        isOpened: false,
    },
    reducers: {
        close: (state) => {
            state.isOpened = false;
        },
        open: (state) => {
            state.isOpened = true;
        },
        toggle: (state) => {
            state.isOpened = !state.isOpened;
        },
    },
})

export const {open, close, toggle} = leftMenuSlice.actions

export default leftMenuSlice.reducer