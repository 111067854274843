import Container from "@mui/material/Container";
import {Box, List, ListItem, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import Layout from "../componens/main/Layout";
import React from "react";
import {COMPANY_NAME} from "../config/AppConstants";
import Footer from "../componens/main/Footer";

const AboutUs = () => {
    return (
        <>
            <Layout/>
            <Container component="main" maxWidth="md">
                <Box mt={8}>
                    <Typography variant="h4">
                        <Trans i18nKey="quickStart.title1"/>
                    </Typography>
                </Box>
                <p>
                    <Typography variant="body1">
                        <Trans i18nKey="quickStart.text1"
                               values={{companyName: COMPANY_NAME}}
                        />
                    </Typography>
                </p>
                <List
                    sx={{
                        listStyleType: 'decimal'
                    }}
                >
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography>
                            <Trans i18nKey="quickStart.step1"/>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography>
                            <Trans i18nKey="quickStart.step2"/>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography>
                            <Trans i18nKey="quickStart.step3"/>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography>
                            <Trans i18nKey="quickStart.step4"/>
                        </Typography>
                    </ListItem>
                </List>
                <p>
                    <Typography variant="body1">
                        <Trans i18nKey="quickStart.text2"
                               values={{companyName: COMPANY_NAME}}/>
                    </Typography>
                </p>
            </Container>
            <Footer/>
        </>
    );
}

export default AboutUs;