import React from 'react';
import Typography from "@mui/material/Typography";
import {close as closeModal} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import {Alert, Box, Button, Container} from "@mui/material";
import {Trans} from "react-i18next";


const UnderConstructionModalContent = ({callback}) => {

    const dispatch = useDispatch();

    return (
        <Container component="main">
            <Box width={500}>
                <Box mt={2}>
                    <Alert severity="warning">
                        <Typography fullWidth>
                            <Trans i18nKey="service.underConstruction"/>
                        </Typography>
                    </Alert>
                </Box>
                <Box mt={2}>
                    <Button
                        size="small"
                        onClick={() => dispatch(closeModal())}
                        variant="outlined"
                    >Close</Button>
                </Box>
            </Box>
        </Container>

    )
}

export default UnderConstructionModalContent;