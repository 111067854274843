import Container from "@mui/material/Container";
import {Box, List, ListItem, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import Layout from "../componens/main/Layout";
import React from "react";
import {COMPANY_NAME} from "../config/AppConstants";
import Footer from "../componens/main/Footer";

const AboutUs = () => {
    return (
        <>
            <Layout/>
            <Container component="main" maxWidth="md">
                <Box mt={8}>
                    <Typography variant="h4">
                        <Trans i18nKey="aboutUs.title1"/>
                    </Typography>
                </Box>
                <p>
                    <Typography variant="body1">
                        <Trans i18nKey="aboutUs.text1"
                               values={{companyName: COMPANY_NAME}}
                        />
                    </Typography>
                </p>
                <Typography variant="h5">
                    <Trans i18nKey="aboutUs.title2"/>
                </Typography>
                <p>
                    <Typography variant="body1">
                        <Trans i18nKey="aboutUs.text2"
                               values={{companyName: COMPANY_NAME}}
                        />
                    </Typography>
                </p>
                <Typography variant="h5">
                    <Trans i18nKey="aboutUs.title3"/>
                </Typography>
                <p>
                    <Typography variant="body1">
                        <Trans i18nKey="aboutUs.text3"/>
                    </Typography>
                </p>
                <Typography variant="h5">
                    <Trans i18nKey="aboutUs.title4"/>
                </Typography>
                <List
                    sx={{
                        listStyleType: 'disc'
                    }}
                >
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            <Trans i18nKey="aboutUs.text4.key1"/>
                        </Typography>
                        <Typography>
                            <Trans i18nKey="aboutUs.text4.value1"/>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            <Trans i18nKey="aboutUs.text4.key2"/>
                        </Typography>
                        <Typography>
                            <Trans i18nKey="aboutUs.text4.value2"/>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            <Trans i18nKey="aboutUs.text4.key3"/>
                        </Typography>
                        <Typography>
                            <Trans i18nKey="aboutUs.text4.value3"/>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            <Trans i18nKey="aboutUs.text4.key4"/>
                        </Typography>
                        <Typography>
                            <Trans i18nKey="aboutUs.text4.value4"/>
                        </Typography>
                    </ListItem>
                </List>
                <Typography variant="h5">
                    <Trans i18nKey="aboutUs.title5"
                           values={{companyName: COMPANY_NAME}}/>
                </Typography>
                <List
                    sx={{
                        listStyleType: 'disc'
                    }}
                >
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            <Trans i18nKey="aboutUs.text5.key1"/>
                        </Typography>
                        <Typography>
                            <Trans i18nKey="aboutUs.text5.value1"/>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            <Trans i18nKey="aboutUs.text5.key2"/>
                        </Typography>
                        <Typography>
                            <Trans i18nKey="aboutUs.text5.value2"/>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            <Trans i18nKey="aboutUs.text5.key3"/>
                        </Typography>
                        <Typography>
                            <Trans i18nKey="aboutUs.text5.value3"/>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            <Trans i18nKey="aboutUs.text5.key4"/>
                        </Typography>
                        <Typography>
                            <Trans i18nKey="aboutUs.text5.value4"/>
                        </Typography>
                    </ListItem>
                </List>
                <p>
                    <Typography variant="body1">
                        <Trans i18nKey="aboutUs.text6"/>
                    </Typography>
                </p>
                <p>
                    <Typography variant="body1">
                        <Trans i18nKey="aboutUs.text7"
                               values={{companyName: COMPANY_NAME}}/>
                    </Typography>
                </p>
            </Container>
            <Footer/>
        </>
    );
}

export default AboutUs;