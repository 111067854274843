import {BrowserRouter, Route, Routes} from "react-router-dom";
import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import AboutUs from "../pages/AboutUs";
import Home from "../pages/Home";
import QuickStart from "../pages/QuickStart";


const AppRoutes = () => {

    const language = useSelector((state) => state.language.value);

    return (
        <BrowserRouter>
            <Fragment>
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path=":language">
                        <Route path={``} element={<AboutUs/>}/>
                        <Route path={`about`} element={<AboutUs/>}/>
                        <Route path={`howto`} element={<QuickStart/>}/>
                    </Route>
                </Routes>
            </Fragment>
        </BrowserRouter>
    )
};

export default AppRoutes;