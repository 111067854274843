import {Backdrop, CircularProgress, CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import AppModal from "./componens/modal/AppModal";
import {theme} from "./themes/theme";
import {useSelector} from "react-redux";
import AppRoutes from "./routes/AppRoutes";

import { useTranslation } from 'react-i18next';

function App() {

    const isBackdropOpened = useSelector((state) => state.backdrop.isOpened);
    const { t, i18n } = useTranslation();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <AppModal/>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={isBackdropOpened}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <CssBaseline/>
                <AppRoutes/>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}


export default App;
