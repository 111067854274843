import Container from "@mui/material/Container";
import {Box, Typography} from "@mui/material";
import Layout from "../componens/main/Layout";
import React from "react";
import Footer from "../componens/main/Footer";
import {Outlet} from "react-router-dom";

const AboutUs = () => {
    return (
        <>
            <Layout/>
            <Container component="main" maxWidth="md">
                <Box mt={8}>
                    <Typography variant="h4">
                        TODO
                    </Typography>
                </Box>

            </Container>
            <Footer/>
            <Outlet/>
        </>
    );
}

export default AboutUs;