import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import {open} from "../../store/leftMenuSlice";
import {open as openModal} from "../../store/modalSlice";
import {useNavigate} from "react-router-dom";
import LanguageSwitch from "./LanguageSwitch";
import {Trans} from "react-i18next";
import {Button, Typography} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {NAVIGATION_PAGES} from "../../config/AppConstants";
import UnderConstructionModalContent from "../modal/UnderConstructionModalContent";

function TopMenu() {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const navigate = useNavigate();

    const openSearchModal = () => {
        dispatch(openModal(<UnderConstructionModalContent/>));
    }


    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box component="img" src="/logo.png" sx={{height: "50px", width: "auto"}}/>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        VIN cleaner
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {NAVIGATION_PAGES.map((page) => (
                            <Button
                                key={page.name}
                                onClick={() => navigate(`/${[language, page.path].join('/')}`)}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                <Trans i18nKey={page.name}/>
                            </Button>
                        ))}
                        <Button variant="contained"
                                color="secondary"
                                id='search-by-vin'
                                onClick={openSearchModal}
                        >
                            <SearchIcon/>
                            <Trans i18nKey='menu.search'/>
                        </Button>
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <LanguageSwitch/>
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <MenuIcon sx={{display: {xs: 'flex', md: 'flex'}, mr: 1, cursor: 'pointer'}}
                                  onClick={() => dispatch(open())}/>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default TopMenu;