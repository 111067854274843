import {Button, Menu, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {change} from "../../store/languageSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {SUPPORTED_LANGUAGES} from "../../i18n";

function LanguageSwitch() {

    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    const language = useSelector((state) => state.language.value);
    const navigate = useNavigate();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const openLanguageMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeLanguageMenu = () => {
        setAnchorEl(null);
    };

    const generateNewPath = (lng) => {
        let newPath = location.pathname;
        let hasLngPath = false;
        SUPPORTED_LANGUAGES.forEach(it => {
            if (newPath.startsWith(`/${it}`)) {
                newPath = newPath.replace(`/${it}`, `/${lng}`);
                hasLngPath = true;
            }
        });
        if (!hasLngPath) {
            newPath = `/${lng}${newPath}`;
        }
        return newPath;
    }


    const switchLanguage = useCallback((lng) => {
        i18n.changeLanguage(lng);
        dispatch(change(lng));
        let newPath = generateNewPath(lng);
        navigate(newPath);
        closeLanguageMenu();
    }, [dispatch, generateNewPath, i18n, navigate]);

    useEffect(() => {
        console.log(i18n)
        if (!language) {
            switchLanguage(i18n.language);
        }
    }, [i18n, language, switchLanguage]);

    return (
        <>
            <Button variant="text"
                    color="secondary"
                    id="selected-language"
                    aria-controls={open ? 'language-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={openLanguageMenu}
            >
                {language && language.toUpperCase()}
            </Button>
            <Menu
                sx={{mt: '45px'}}
                id="language-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={closeLanguageMenu}
            >
                {
                    SUPPORTED_LANGUAGES.map((lng) =>
                        <MenuItem key={`language-menu-item-${lng}`} onClick={() => switchLanguage(lng)}>
                            <Typography textAlign="center">{lng.toUpperCase()}</Typography>
                        </MenuItem>
                    )
                }
            </Menu>
        </>
    );
}

export default LanguageSwitch;