import React, {Fragment} from "react";
import TopMenu from "./TopMenu";
import LeftMenu from "./LeftMenu";
import Footer from "./Footer";

const Layout = () => {
    return (
        <>
            <TopMenu/>
            <LeftMenu/>
            {/*<Footer/>*/}
        </>
    )
};

export default Layout;