import {createSlice} from '@reduxjs/toolkit'

export const languageSlice = createSlice({
    name: 'language',
    initialState: {
        value: null,
    },
    reducers: {
        change: (state, action) => {
            state.value = action.payload;
        },
    },
})

export const {change} = languageSlice.actions

export default languageSlice.reducer