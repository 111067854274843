import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

export const SUPPORTED_LANGUAGES = ['en', 'ru'];

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        supportedLngs: SUPPORTED_LANGUAGES,
        fallbackLng: 'en',
        debug: true,
        detection: {
            order: ['querystring', 'path', 'cookie', 'htmlTag', 'localStorage', 'sessionStorage', 'subdomain'],
            caches: ['cookie', 'localStorage'],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng'
        },
        backend: {
            loadPath: "/locales/{{lng}}/translation.json"
        },
        interpolation: {escapeValue: false},
        react: {useSuspense: false}
    });

export default i18n;