import {BottomNavigation, BottomNavigationAction, Button, Divider, Typography} from "@mui/material";
import {useState} from "react";
import * as React from "react";
import {Trans} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {NAVIGATION_PAGES} from "../../config/AppConstants";


const Footer = () => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const language = useSelector((state) => state.language.value);
    return (
        <>
            <Divider/>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                {NAVIGATION_PAGES.map((page) => (
                    <BottomNavigationAction
                        key={`footer-nav-${page.name}`}
                        label={<Trans i18nKey={page.name}/>}
                        onClick={() => navigate(`/${[language, page.path].join('/')}`)}
                    />
                ))}
            </BottomNavigation>
            <Divider/>
            <Typography variant="body2">© {new Date().getFullYear()}, vin-cleaner.com</Typography>
        </>
    )
};

export default Footer;