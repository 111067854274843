import {configureStore} from '@reduxjs/toolkit'
import backdropReducer from './backdropSlice'
import modalReducer from './modalSlice'
import leftMenuReducer from "./leftMenuSlice";
import languageReducer from "./languageSlice";

export default configureStore({
    reducer: {
        backdrop: backdropReducer,
        modal: modalReducer,
        leftMenu: leftMenuReducer,
        language: languageReducer
    },
})